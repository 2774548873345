import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment';
import { Chantier } from '../model/chantier.model';
import { HttpClient } from '@angular/common/http';
import { Ville } from '../model/ville.model';
import { TypeBatiment } from '../model/typeBatiment.model';
import { TypeChantier } from '../model/typeChantier.model';
import {CasDeMontage} from '../model/cas-de-montage.model';
import * as moment from 'moment';
import { ConfigService } from './config.service';
import { Rugosite } from '../model/rugosite.model';

@Injectable({
    providedIn: 'root',
})
export class ChantierService {

    constructor(
        protected http: HttpClient,
        private config: ConfigService
    ) {}

    getChantiers(codeClient: string,
                 archive: boolean,
                 deplacerMesChantiers: boolean,
                 isCasDeMontage: boolean,
                 dateDebut: string = null,
                 libelle: string = null,
                 ville: string = null,
                 dep: string = null
    ): Observable<Chantier[]> {

      const params: {
        codeClient?: string,
        archive?: string,
        deplacerMesChantiers?: string,
        isCasDeMontage?: string,
        'dateDebut[gte]'?: string,
        libelle?: string,
        'ville.ville'?: string,
        'ville.dep'?: string
      } = {};

      params.codeClient = codeClient;
      params.archive = archive.toString();
      params.deplacerMesChantiers = deplacerMesChantiers.toString();
      if (isCasDeMontage !== null) {
        params.isCasDeMontage = isCasDeMontage.toString();
      }
      if (libelle) {
        params.libelle = libelle;
      }
      if (ville) {
        params['ville.ville'] = ville.toUpperCase();
      }
      if (dep) {
        params['ville.dep'] = dep;
      }
      if (dateDebut) {
        params['dateDebut[gte]'] = moment(dateDebut, 'DD/MM/YYYY').unix().toString();
      }

      return this
          .http
          .get<Chantier[]>(this.config.apiEndpoint + environment.chantier_get_collection, {params});
    }

    getChantier(id: string): Observable<Chantier> {
        return this
            .http
            .get<Chantier>(this.config.apiEndpoint + environment.chantier_get_item.replace('{id}', id));
    }

    addOrUpdateChantier(chantier: Chantier): Observable<Chantier> {
      chantier.ville = environment.ville_get_item.replace('{id}', (chantier.ville as Ville).id.toString());
      if (chantier.typeBat) {
        chantier.typeBat = environment.type_batiment_get_item.replace('{id}', (chantier.typeBat as TypeBatiment).id.toString());
      }
      if (chantier.type) {
        chantier.type = environment.type_chantier_get_item.replace('{id}', (chantier.type as TypeChantier).id.toString());
      }
      if (chantier.rugosite) {
        chantier.rugosite = environment.rugosite_get_item.replace('{id}', (chantier.rugosite as Rugosite).id.toString());
      }

      if (chantier.id) {
        return this
          .http
          .patch<Chantier>(this.config.apiEndpoint + environment.chantier_patch_item.replace('{id}', chantier.id.toString()), chantier);
      }
      return this
        .http
        .post<Chantier>(this.config.apiEndpoint + environment.chantier_get_collection, chantier);
    }

    archiveChantier(idChantier: number) {
      return this
        .http
        .patch<Chantier>(this.config.apiEndpoint + environment.chantier_patch_item.replace('{id}', idChantier.toString()), {archive: true});
    }

    getConceptionExport(idConception: number, famille: string): Observable<string> {
        return this
          .http
          .post<string>(this.config.apiEndpoint + environment.conception_export_element, {idConception, famille});
    }

    getSuiviChantierExport(idConception: number, famille: string): Observable<string> {
        return this
          .http
          .post<string>(this.config.apiEndpoint + environment.suivi_chantier_export, {idConception, famille});
    }

    getCasDeMontageExport(casDeMontage: CasDeMontage) {
      return this
        .http
        .post<string>(this.config.apiEndpoint + environment.casDeMontage_export, {idCasDeMontage: casDeMontage.id.toString()});
    }
}
