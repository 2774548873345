import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as HttpStatus from 'http-status-codes';
import { AuthenticationService } from '../security/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MyErrorHandler implements ErrorHandler {
  constructor(
    protected readonly toastService: ToastrService,
    protected readonly authenticationService: AuthenticationService,

  ) { }

  handleError(error: Error | HttpErrorResponse) {
    console.error(error);

    if (error instanceof HttpErrorResponse) {
      if (error.status === HttpStatus.UNAUTHORIZED) {
        this.authenticationService.disconnect();
        this.toastService.warning('Votre session a expirée.', 'Login');
        document.location.replace('/login');

        return;
      }

      if (error.status === HttpStatus.NOT_FOUND || error.status === HttpStatus.INTERNAL_SERVER_ERROR) {
        this.toastService.error('Une erreur technique est survenue, veuillez ressayer plus tard.', 'Erreur');
        return;
      }
      return;
    }
  }
}
