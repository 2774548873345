import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Config } from '../model/config';

@Injectable()
export class ConfigService extends Config {
  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  public load() {
    return this
      .httpClient
      .get<Config>('/assets/config/config.json')
      .pipe(
        tap(configuration => {
          for (const prop in configuration) {
            if (!configuration.hasOwnProperty(prop)) {
              continue;
            }

            this[prop] = configuration[prop];
          }
        }),
      )
      .toPromise()
      ;
  }
}
