import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/security/authentication.service';
import { finalize } from 'rxjs/operators';
import { MdpOublieModalComponent } from './motDePasseOublie/mdp-oublie-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ConfigService } from '../../shared/services/config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  private static readonly HTTP_STATUS_UNAUTHORIZED = 401;
  formData: any = {};
  isLoading = false;
  bsModalRef: BsModalRef;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastService: ToastrService,
    private modalService: BsModalService,
    public configService: ConfigService,
  ) {
    localStorage.clear();
  }

  signin(): void {
    this.isLoading = true;
    this.authenticationService.connect(this.formData.username, this.formData.password)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        () => this.router.navigate(['/home']),
        error => this.handleLoginFailure(error)
      );
  }

  openModalMdpOublie() {
    this.bsModalRef = this.modalService.show(MdpOublieModalComponent, {animated: true, class: 'modal-lg'});
  }

  getMail() {
    return 'mailto:' + this.configService.contactMailCompte
      + '&subject='
      + encodeURIComponent(this.configService.subjectMailCompte);
  }

  /**
   * Affiche un message d'erreur si les identifiants sont invalides.
   */
  private handleLoginFailure(error: HttpErrorResponse) {
    if (error.status === LoginComponent.HTTP_STATUS_UNAUTHORIZED) {
      this.toastService.error('Identifiants invalides.');
    } else {
      console.log(error);
      this.toastService.error('Une erreur s\'est produite. Veuillez réessayer ultérieurement.');
    }
  }

}
