import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment';
import { Chantier } from '../model/chantier.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ElementConception } from '../model/elementConception.model';
import { Vitrage } from '../model/vitrage.model';
import { ConfigService } from './config.service';
import { Matiere } from '../model/matiere.model';

@Injectable({
    providedIn: 'root',
})
export class ElementConceptionService {

    constructor(
        protected http: HttpClient,
        private config: ConfigService
    ) {}

    addOrUpdateRepere(repere: ElementConception): Observable<ElementConception> {

      repere.conception = environment.chantier_get_item.replace('{id}', (repere.conception as Chantier).id.toString());
      repere.vitrage = environment.vitrage_get_item.replace('{id}', (repere.vitrage as Vitrage).id.toString());
      repere.matiere = environment.matiere_get_item.replace('{id}', (repere.matiere as Matiere).id.toString());

      if (repere.id) {
        const url = this.config.apiEndpoint + environment.repere_patch_item.replace('{id}', repere.id.toString());
        return this
          .http
          .patch<ElementConception>(url, repere);
      }
      return this
        .http
        .post<ElementConception>(this.config.apiEndpoint + environment.repere_post_collection, repere);
    }

    getRepere(id: string): Observable<ElementConception> {
      return this
        .http
        .get<ElementConception>(this.config.apiEndpoint + environment.repere_get_item.replace('{id}', id));
    }

    deleteRepere(id: string): Observable<void> {
      return this
        .http
        .delete<void>(this.config.apiEndpoint + environment.repere_delete_item.replace('{id}', id));
    }

    calculPoids(repere: ElementConception): Observable<number> {
      return this
        .http
        .post<number>(this.config.apiEndpoint + environment.repere_calcul_weight, repere);
    }
}
