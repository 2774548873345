import { Picto } from '../model/picto.model';

export const pictoMenuiserie: Picto[] = [

  {
    code : 'rugosite',
    titre : 'Rugosité',
    infos :
      [
        {
          image1 : null,
          image2: null,
          texte : '0 : Mer ou zone côtière exposée aux vents de mer: lacs et plans d\'eau parcourus par le vent sur une distance d\'au moins 5km<br><br>'
          + 'II : Rase campagne avec ou non quelques obstacles isolés (arbres, bâtiments, etc) séparés les uns des autres de plus de 40 fois leur hauteur<br><br>'
          + 'IIIa: Campagne avec haies; vignobles; bocage; habitat dispersé<br><br>'
          + 'IIIb: Zones urbanisée ou industrielles; bocage dense; vergers<br><br>'
          + 'IV : Zones urbaines dont au moins 15% de la surface sont recouverts de bâtiments dont la hauteur moyenne est supérieure à 15m; forêts<br><br>'
        }
      ]
  },
];
