import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../model/user.model';
import { environment } from '../../environment';
import { ConfigService } from './config.service';
import { UserRole } from '../enum/user-role.enum';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private static readonly HTTP_STATUS_UNAUTHORIZED = 401;

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) {
  }

  getUserByUsername(mail: string): Observable<User> {
    const apiCreateEndpoint = this.config.apiEndpoint + environment.user_get_collection;

    return this.http.get<User>(apiCreateEndpoint, {params: {mail}})
      .pipe(
        map(users => users[0]),
        map<User, User>(user => this.checkRole(user))
      );
  }

  sendMailWithClientInformations(phoneNumber: string, firstname: string, lastname: string): Observable<any> {
    const apiCreateEndpoint = this.config.apiEndpoint + environment.sendMailWithClientInformations;

    return this
      .http
      .post<any>(
        apiCreateEndpoint,
        {phoneNumber, firstname, lastname}
      );
  }

  private checkRole(user: User) {
    if (!user.roles.find(r => r === UserRole.ROLE_MDT)) {
      throw new HttpErrorResponse({status: UserService.HTTP_STATUS_UNAUTHORIZED});
    }
    return user;
  }
}
