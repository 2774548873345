import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './security/auth-guard.service';
import { ConfirmComponent } from './modals/confirm/confirm.component';
import { InfoComponent } from './modals/info/info.component';
import { ModalModule } from 'ngx-bootstrap';
import { LoadingComponent } from './modals/loading/loading.component';
import { UserInactivityComponent } from './modals/userInactivity/user-inactivity.component';
import { FormsModule } from '@angular/forms';
import { SidepanelComponent } from './component/sidepanel/sidepanel.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
  ConfirmComponent,
  InfoComponent,
  LoadingComponent,
  UserInactivityComponent,
  SidepanelComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    FormsModule,
    RouterModule,
  ],
  providers: [
    AuthGuard,
  ],
  entryComponents: [
    ConfirmComponent,
    InfoComponent,
    LoadingComponent,
    UserInactivityComponent,
  ],
  exports: [
    SidepanelComponent,
  ]
})
export class SharedModule { }
