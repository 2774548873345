import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/security/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { finalize, mergeMap } from 'rxjs/operators';

@Component({
  templateUrl: './reinit-pwd.component.html',
  styleUrls: ['./reinit-pwd.component.scss']
})
export class ReinitPwdComponent implements OnInit{
  public isLoading = false;
  public reset: any = {};
  public isResetFalse = false;
  public token = '';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.token = params.token;
      })
    ;
  }

  onReset() {
    if (this.reset.pwd1 && this.reset.pwd1 !== '' && this.reset.pwd2 && this.reset.pwd2 !== '' && this.reset.pwd1 === this.reset.pwd2) {
      this.isLoading = true;
      this.authenticationService.changePwd(this.reset.pwd1, this.token)
        .pipe(
          mergeMap(retour => this.authenticationService.connect(retour, this.reset.pwd1)),
          finalize(() => this.isLoading = false),
        )
        .subscribe(
          retour => {
              return this.router.navigate(['/home']);
          },
          error => this.toastr.error(error, 'Réinitialisation mot de passe'),
          );
    } else {
      this.isResetFalse = true;
    }
  }

}
