import { Component, OnInit } from '@angular/core';
import {TabDirective} from 'ngx-bootstrap';

@Component({
  templateUrl: './home-archive.component.html',
  styleUrls: ['./home-archive.component.scss']
})
export class HomeArchiveComponent implements OnInit {

  readonly ONGLET_MENUISERIE = 'ongletMenuiserie';
  readonly ONGLET_MUR_RIDEAU = 'ongletMurRideau';
  isLoadingMenuiserie = false;
  isLoadingMurRideau = false;
  isShowMenuiserie = false;
  isShowMurRideau = false;

  constructor() {}

  ngOnInit() {
    this.isShowMenuiserie = true;
  }

  onSelect(data: TabDirective): void {
    if (this.ONGLET_MENUISERIE === data.id) {
      this.isShowMenuiserie = true;
      this.isShowMurRideau = false;
    } else if (this.ONGLET_MUR_RIDEAU === data.id) {
      this.isShowMenuiserie = false;
      this.isShowMurRideau = true;
    }
  }

}
