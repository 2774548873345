import { Component, OnInit } from '@angular/core';
import { ChantierService } from '../../shared/services/chantier.service';
import { Chantier } from '../../shared/model/chantier.model';
import { filter, finalize, map } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { PdfService } from '../../shared/services/pdf.service';
import { ToastrService } from 'ngx-toastr';
import { CasDeMontage } from '../../shared/model/cas-de-montage.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LoadingComponent } from '../../shared/modals/loading/loading.component';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  templateUrl: './home-cdm.component.html',
  styleUrls: ['./home-cdm.component.scss']
})
export class HomeCdmComponent implements OnInit {
  collapsedElementID: number;
  chantiers: Chantier[] = [];
  isLoading = false;
  direction = 'asc';
  isSortedByVille = false;
  isSortedByDate = false;
  bsModalRef: BsModalRef;

  constructor(
      private chantierService: ChantierService,
      private localStorageService: LocalStorageService,
      private pdfService: PdfService,
      private toastr: ToastrService,
      private modalService: BsModalService,
      private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getChantiers();
  }

  public sortVille() {
    this.isSortedByVille = true;
    this.isSortedByDate = false;
    this.chantiers = this.chantiers.sort((a: Chantier, b: Chantier) => {
      const aVille = (typeof a.ville === 'string') ? a.ville : a.ville.ville;
      const bVille = (typeof b.ville === 'string') ? b.ville : b.ville.ville;

      if (this.direction === 'asc') {
        return aVille.localeCompare(bVille);
      } else {
        return bVille.localeCompare(aVille);
      }
    });
  }

  public sortDate() {
    this.isSortedByVille = false;
    this.isSortedByDate = true;
    this.chantiers = this.chantiers.sort((a: Chantier, b: Chantier) => {
      if (this.direction === 'asc') {
        return this.compareDate(a.dateDebut, b.dateDebut);
      } else {
        return this.compareDate(b.dateDebut, a.dateDebut);
      }
    });
  }

  public sort() {
    if (this.isSortedByDate) {
      this.sortDate();
    } else if (this.isSortedByVille) {
      this.sortVille();
    }
  }

  private getChantiers(withShowDetail = true) {
    this.isLoading = true;
    const codeClient = this.localStorageService.getUser().codeClient;
    this.chantierService.getChantiers(codeClient, false, false, true)
        .pipe(
            finalize(() => this.isLoading = false)
        )
        .subscribe(chantiers => {
          this.chantiers = chantiers;
          if (withShowDetail) {
            setTimeout(() => this.showChantierDetail(), 2000);
          }
        });
  }

  private showChantierDetail(): void {
    this
      .route
      .paramMap
      .pipe(
        filter((params: ParamMap) => !!params.get('idChantier')),
        map((params: ParamMap) => params.get('idChantier')),
      )
      .subscribe(idChantier => {
        this.collapsedElementID = +idChantier;
        document.getElementById(idChantier).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      })
    ;
  }

  private compareDate(dateA: number, dateB: number) {
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  private exportElement(elementTableau: CasDeMontage, event, chantier: Chantier): void {
    event.stopPropagation();
    chantier.selectedCdm = elementTableau;
    if (!chantier.selectedCdm) {
      this.toastr.error('Pour exporter, veuillez choisir un cas de montage.', 'Export');
    } else {
      const initialState = {
        title: 'Export'
      };
      this.bsModalRef = this.modalService.show(LoadingComponent, {initialState, animated: true, class: 'modal-lg'});
      this.chantierService.getCasDeMontageExport(chantier.selectedCdm)
        .pipe(
          finalize(() => this.bsModalRef.hide())
        )
        .subscribe(pdf => {
        this.pdfService.openFromBase64(pdf);
      });
    }
  }

  selectLine(casDeMontage: CasDeMontage, chantier: Chantier) {
    chantier.selectedCdm = casDeMontage;
  }

  archiveChantier(idChantier: number) {
    const initialState = {
      message: 'Etes-vous sûr de vouloir archiver cette conception ?' +
        '<br>' +
        'Si vous confirmez, les informations liées à cette conception seront déplacées dans la partie <strong>ARCHIVES</strong>',
      title: 'ARCHIVAGE'
    };
    this.bsModalRef = this.modalService.show(ConfirmComponent, {initialState, animated: true, class: 'modal-lg'});
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result) {
        this.isLoading = true;
        this.chantierService.archiveChantier(idChantier)
          .subscribe(() => this.getChantiers(false));
        this.bsModalRef.hide();
      }
    });
  }
}
