import { NgModule } from '@angular/core';

import { BsDatepickerModule, CollapseModule, TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { CasDeMontageComponent } from './cas-de-montage/cas-de-montage.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared/shared.module';
import { HomeCdmComponent } from './home-cdm/home-cdm.component';

defineLocale('fr', frLocale);

@NgModule({
  declarations: [
    HomeCdmComponent,
    CasDeMontageComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    RouterModule,
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    SharedModule
  ],
  providers: [],
})
export class HomeCdmModule { }
