import { Component, OnInit, ViewChild } from '@angular/core';
import { PieceService } from '../../shared/services/piece.service';
import { Piece } from '../../shared/model/typos/piece.model';
import { CasDeMontage } from '../../shared/model/cas-de-montage.model';
import { GammesService } from '../../shared/services/gammes.service';
import { combineLatest, Observable, of } from 'rxjs';
import { Gamme } from '../../shared/model/gamme.model';
import { finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as clone from 'clone';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CasDeMontageService } from '../../shared/services/cas-de-montage.service';
import { NgForm } from '@angular/forms';
import { Typo } from '../../shared/model/typos/typo.model';
import { Type } from '../../shared/model/typos/type.model';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';
import { MrCasMontage } from '../../shared/enum/mr-cas-montage.enum';
import { Chantier } from '../../shared/model/chantier.model';
import { ChantierService } from '../../shared/services/chantier.service';

@Component({
  selector: 'app-cas-de-montage',
  templateUrl: './cas-de-montage.component.html',
  styleUrls: ['./cas-de-montage.component.scss']
})
export class CasDeMontageComponent implements OnInit {

  readonly CODE_PIECE_MANCHON = 'MANCHON';
  readonly CODE_PIECE_SABOT = 'SABOT';
  readonly CODE_TYPE_MONTAGE_FIXE_SUSPENDU = 'FIXE_SUSPENDU';

  @ViewChild('f') public form: NgForm;

  casDeMontage: CasDeMontage = {} as CasDeMontage;
  pieces: Piece[];
  bsModalRef: BsModalRef;

  isLoadingGet = true;
  isLoadingGammiste = false;
  isLoadingProfil = false;
  isLoadingSerie = false;
  isLoadingPiece = true;
  isLoadingCodeProduit = false;
  isLoading = false;
  isLoadingDel = false;

  idChantier: string;
  chantier: Chantier;

  gammistes: Gamme[];
  series: Gamme[] = [];
  profils: Gamme[] = [];

  public isInitPiece = true;
  public isInitGamme = true;
  public isInitSerie = true;
  public readonly PIECECHOICE = 'pieceChoice';
  public readonly ARTICLECODECHOICE = 'articleCodeChoice';

  constructor(
    private pieceService: PieceService,
    private gammeService: GammesService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private casDeMontageService: CasDeMontageService,
    private chantierService: ChantierService,
    ) { }

  ngOnInit() {
    this
      .pieceService
      .getPieces()
      .pipe(
        finalize(() => this.isLoadingPiece = false)
      )
      .subscribe(pieces => {
        this.pieces = pieces;
        this.initDatasFromUrl();
      })
    ;

    this.casDeMontage.semelleDebordante = false;
  }

  private initDatasFromUrl(): void {
    this.isLoadingGet = true;
    this
      .route
      .paramMap
      .pipe(
        tap((params: ParamMap) => this.idChantier = params.get('idChantier')),
        switchMap((params: ParamMap) => params.get('idCasDeMontage')
          ? combineLatest([this.getCasDeMontage(params.get('idCasDeMontage')), this.getChantier(params.get('idChantier'))])
          : this.getChantier(params.get('idChantier'))
        ),
      )
      .subscribe(() => this.isLoadingGet = false);
  }

  private getChantier(id: string): Observable<Chantier> {
    return this
      .chantierService
      .getChantier(id)
      .pipe(
        tap(chantier => this.chantier = chantier),
      )
    ;
  }

  private getCasDeMontage(id: string): Observable<CasDeMontage> {
    this.isLoadingGet = true;
    return this
      .casDeMontageService
      .getCasDeMontage(id)
      .pipe(
        map(casDeMontage => {
          casDeMontage.choiceMurRideaux = (casDeMontage.piece as Piece).code ? MrCasMontage.PIECE_CHOICE : MrCasMontage.ARTICLE_CODE_CHOICE;
          casDeMontage.piece = this.pieces.find((element) => element.code === (casDeMontage.piece as Piece).code);
          casDeMontage.type = (casDeMontage.piece as Piece).types.find((element) => element.code === (casDeMontage.type as Type).code);

          return casDeMontage;
        }),
        tap(casDeMontage => this.casDeMontage = casDeMontage),
        tap(() => this.onPieceChange()),
        tap(() => this.onGammisteChange()),
        tap(() => this.onSerieChange()),
      )
    ;
  }

  displayTypeMontage(): boolean {
    if (this.casDeMontage.piece && (this.casDeMontage.piece as Piece).code === this.CODE_PIECE_SABOT) {
      return !!this.casDeMontage.gamme
          && !!this.casDeMontage.serie
          && !!this.casDeMontage.type
          && !!this.casDeMontage.forme;
    }
    if (this.casDeMontage.piece && (this.casDeMontage.piece as Piece).code === this.CODE_PIECE_MANCHON) {
      return !!this.casDeMontage.gamme
          && !!this.casDeMontage.serie
          && !!this.casDeMontage.type
          && !!this.casDeMontage.forme
          && !!this.casDeMontage.profil;
    }
    return false;
  }

  displayCharge(): boolean {
    return this.displayTypeMontage() && !!this.casDeMontage.typeMontage;
  }

  public onPieceChange() {
    if (this.isInitPiece && this.casDeMontage.id) {
      this.isInitPiece = false;
    } else {
      this.resetProduit('pieceMR');
    }

    if (this.casDeMontage.piece) {
      this.isLoadingGammiste = true;
      this.gammeService.getGammistes(
        this.casDeMontage.piece && (this.casDeMontage.piece as Piece).code === this.CODE_PIECE_MANCHON ? 'M' : 'S')
        .pipe(
          finalize(() => this.isLoadingGammiste = false)
        )
        .subscribe(gammistes => this.gammistes = gammistes);
    }
  }

  public onGammisteChange() {
    if (this.isInitGamme && this.casDeMontage.id) {
      this.isInitGamme = false;
    } else {
      this.resetProduit('gammiste');
    }

    if (this.casDeMontage.piece
      && this.casDeMontage.gamme) {
      this.isLoadingSerie = true;
      this.gammeService.getSeries(this.casDeMontage.gamme, (this.casDeMontage.piece as Piece).code === this.CODE_PIECE_MANCHON ? 'M' : 'S')
        .pipe(
          finalize(() => this.isLoadingSerie = false)
        )
        .subscribe( series => this.series = series);
    }
  }

  public onSerieChange() {
    if (this.isInitSerie && this.casDeMontage.id) {
      this.isInitSerie = false;
    } else {
      this.casDeMontage.profil = null;
      this.profils = [];
    }

    if (this.casDeMontage.piece
      && this.casDeMontage.gamme
      && this.casDeMontage.serie) {
      this.isLoadingProfil = true;
      this.gammeService.getProfils(this.casDeMontage.gamme, this.casDeMontage.serie, null,
        null, (this.casDeMontage.piece as Piece).code === this.CODE_PIECE_MANCHON ? 'M' : 'S')
        .pipe(
          finalize(() => this.isLoadingProfil = false)
        )
        .subscribe( profils => this.profils = profils);
    }
  }

  public submitForm(onContinue = true): void {
    if (!this.form.valid) {
      return;
    }

    this.addOrUpdateCasDeMontage(onContinue);
  }

  public addOrUpdateCasDeMontage(onContinue: boolean) {
    this.isLoading = true;

    this.gammeService.getGammeByCdm(this.casDeMontage)
      .pipe(
        mergeMap(gamme => this.updateIfGammeCorrect(gamme)),
        finalize (() => this.isLoading = false)
      )
      .subscribe(cdm => {
        if (!this.casDeMontage.id) {
          if (!cdm.isValide) {
            this.toastr.warning('Le cas de montage a été créé mais celui-ci n\'est pas validé, veuillez contacter notre ' +
              'bureau d\'études au 02 51 29 10 05 ou par mail à contact@louineau.com.',
              'Cas de montage');
            this.casDeMontage.id = cdm.id;
          } else {
            this.toastr.success('Votre cas de montage a bien été ajouté à votre chantier.', 'Cas de montage');
            if (!onContinue) {
              this.router.navigate(['/home-cdm', this.chantier.id]);
            } else {
              this.getChantier((this.chantier as Chantier).id.toString()).subscribe();
              this.casDeMontage.id = null;
              this.casDeMontage.libelle = null;
            }
          }
        } else {
          if (!cdm.isValide) {
            this.toastr.warning('Le cas de montage a été créé mais celui-ci n\'est pas validé',
              'Cas de montage');
          } else {
            this.toastr.success('Votre cas de montage a bien été modifié.', 'Cas de montage');
            if (!onContinue) {
              this.router.navigate(['/home-cdm', this.chantier.id]);
            } else {
              this.getChantier((this.chantier as Chantier).id.toString()).subscribe();
              this.casDeMontage.id = null;
              this.casDeMontage.libelle = null;
              window.scroll(0, 0);
            }
          }
        }
      });
  }

  private updateIfGammeCorrect(gamme: Gamme): Observable<CasDeMontage> {
    if (gamme) {
      this.casDeMontage.codeArticle = gamme.codeproduit;
      const casDeMontage = clone(this.casDeMontage);
      casDeMontage.conception = this.idChantier;
      return this.casDeMontageService.addOrUpdateCasDeMontage(casDeMontage)
        .pipe(
          finalize( () => this.isLoading = false)
        );
    } else {
      this.toastr.error('Il n\'existe pas d\'article pour ces informations.', 'Cas de montage');
      return of();
    }
  }

  openDeleteConfirm() {
    const initialState = {
      message: 'Etes-vous sûr de vouloir supprimer ce cas de montage ?',
      title: 'SUPPRESSION'
    };
    this.bsModalRef = this.modalService.show(ConfirmComponent, {initialState, animated: true, class: 'modal-lg'});
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result) {
        this.delete();
        this.bsModalRef.hide();
      }
    });
  }

  public delete() {

    this.isLoadingDel = true;
    this.casDeMontageService.deleteCasDeMontage(this.casDeMontage.id.toString())
      .pipe(
        finalize( () => this.isLoadingGet = false)
      )
      .subscribe(() => {
        this.form.resetForm();
        this.toastr.success('Votre cas de montage a bien été supprimé', 'Cas de montage');
        this.router.navigate(['/home-cdm']);
      });
  }

  getGammeByArticle() {
    if (this.casDeMontage.codeArticle && this.casDeMontage.codeArticle !== '') {
      this.isLoadingCodeProduit = true;
      this.gammeService.getGammeByCode(this.casDeMontage.codeArticle)
        .pipe(
          finalize(() => this.isLoadingCodeProduit = false)
        )
        .subscribe(gamme => {
          if (!gamme) {
            this.toastr.error('Il n\'existe pas d\'articles avec ce code', 'Cas de montage');
            return;
          }
          if (gamme.codeproduit.startsWith('S')) {
            this.casDeMontage.piece = this.pieces.find(p => p.code === this.CODE_PIECE_SABOT);
            this.onPieceChange();
            this.casDeMontage.type = this.casDeMontage.piece.types.find(t => t.code === gamme.modele);
            this.casDeMontage.forme = this.casDeMontage.type.formes.find(f => f.code === gamme.forme);
          } else {
            this.casDeMontage.piece = this.pieces.find(p => p.code === this.CODE_PIECE_MANCHON);
            this.onPieceChange();
            this.casDeMontage.type = this.casDeMontage.piece.types.find(t => t.code === gamme.famille);
            this.casDeMontage.forme = this.casDeMontage.type.formes.find(f => f.code === gamme.forme);
          }
          this.casDeMontage.gamme = gamme.gammiste;
          this.onGammisteChange();
          this.casDeMontage.serie = gamme.serie;
          this.onSerieChange();
          this.casDeMontage.profil = gamme.profil;
        });
    }
  }

  public onTypeChange() {
    this.casDeMontage.forme = (this.casDeMontage.type as Type).formes[0];
    this.casDeMontage.typeMontage = (this.casDeMontage.type as Type).typeMontages[0];
  }

  public compareTypo(t1: Typo, t2: Typo) {
    return t1 && t2 && t1.code === t2.code;
  }

  onChoiceMrChange() {
    if (this.casDeMontage.choiceMurRideaux === MrCasMontage.ARTICLE_CODE_CHOICE) {
      this.casDeMontage.piece = null;
    } else if (this.casDeMontage.choiceMurRideaux === MrCasMontage.PIECE_CHOICE) {
      this.casDeMontage.codeArticle = null;
    }
  }

  isDisabled(data: any[]) {
    return data === undefined || data.length === 0;
  }

  private resetProduit(origine: string) {
    switch (origine) {
      case 'pieceMR':
        this.casDeMontage.gamme = undefined;
        this.casDeMontage.serie = null;
        this.series = [];
        this.casDeMontage.profil = null;
        this.profils = [];
        this.casDeMontage.typeMontage = null;
        this.casDeMontage.forme = null;
        this.casDeMontage.semelleDebordante = false;
        this.casDeMontage.type = null;
        break;
      case 'gammiste':
        this.casDeMontage.serie = null;
        this.series = [];
        this.casDeMontage.profil = null;
        this.profils = [];
        this.casDeMontage.typeMontage = null;
        this.casDeMontage.forme = null;
        break;
    }
  }
}
