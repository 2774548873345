import { Component } from '@angular/core';
import * as moment from 'moment';
import { FacebookService } from 'ngx-facebook';
import { VersionApiService } from '../../shared/services/versionApi.service';
import { ConfigService } from '../../shared/services/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  version: string;
  year = moment().format('YYYY');
  versionAPI = '';

  constructor(
    private fb: FacebookService,
    private versionService: VersionApiService,
    private configservice: ConfigService,
  ) {
    this.fb.init({
      version: 'v2.9',
      xfbml: true
    });
    versionService.getVersion().subscribe(v => this.versionAPI = v);
    this.version = this.configservice.version;
  }
}
