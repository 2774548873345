import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixconceptComponent } from './fixconcept/fixconcept.component';

@NgModule({
  declarations: [FixconceptComponent],
  imports: [
    CommonModule
  ]
})
export class FixconceptModule { }
