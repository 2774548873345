import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fixconcept',
  templateUrl: './fixconcept.component.html',
  styleUrls: ['./fixconcept.component.scss']
})
export class FixconceptComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
