import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ChantierService } from '../../shared/services/chantier.service';
import { Chantier } from '../../shared/model/chantier.model';
import { finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { DeptsService } from '../../shared/services/depts.service';
import { Observable, of } from 'rxjs';
import { Dept } from '../../shared/model/dept.model';
import { TypeBatiment } from '../../shared/model/typeBatiment.model';
import { TypeChantier } from '../../shared/model/typeChantier.model';
import { TypeBatimentService } from '../../shared/services/typeBatiment.service';
import { TypeChantierService } from '../../shared/services/typeChantier.service';
import { Ville } from '../../shared/model/ville.model';
import { VillesService } from '../../shared/services/villes.service';
import { UtilsService } from '../../shared/services/utils.service';
import * as moment from 'moment';
import * as clone from 'clone';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { RouterExtService } from '../../shared/services/router-ext.service';
import { RugositeService } from '../../shared/services/rugosite.service';
import { Rugosite } from '../../shared/model/rugosite.model';
import { Picto } from '../../shared/model/picto.model';
import { pictoMenuiserie } from '../../shared/pictos/menuiserie';

@Component({
  selector: 'app-chantier',
  templateUrl: './chantier.component.html',
  styleUrls: ['./chantier.component.scss']
})
export class ChantierComponent implements OnInit {

  chantier: Chantier = {
    elementsTableauChantiers: [],
    ville: {dep: null, ville: null, id: null},
    libelle: null,
    id: null,
    isCasDeMontage: null,
    codeClient: null,
    typeBat: null,
    dateFinStr: null,
    dateDebutStr: null,
    dateDebut: null,
    dateFin: null,
    type: null,
    coutHoraireGa: null,
    elementsConceptions: [],
    selectedCdm: null,
    selectedElement: null
  } as Chantier;

  isVilleConform = true;
  isDateCorrect = true;

  depts$: Observable<Dept[]>;
  typeBatiments$: Observable<TypeBatiment[]>;
  typeChantiers$: Observable<TypeChantier[]>;
  rugosites$: Observable<Rugosite[]>;
  villes: Ville[];
  villesStr: string[];
  isLoadingGet = false;
  isLoadingVille = false;
  isLoading = false;
  public isCollapsed = true;
  public picto: Picto;

  readonly hauteursBatiment = [
    {hauteur: 8, label: 'H<=9'},
    {hauteur: 10, label: '9<H<=18'},
    {hauteur: 20, label: '18<H<=28'},
    {hauteur: 30, label: '28<H<=50'},
    {hauteur: 60, label: '50<H<=100'},
  ];
  public createdChantier: Chantier;

  constructor(
      private localeService: BsLocaleService,
      private route: ActivatedRoute,
      private router: Router,
      private chantierService: ChantierService,
      private deptService: DeptsService,
      private typeBatimentService: TypeBatimentService,
      private typeChantierService: TypeChantierService,
      private rugositeService: RugositeService,
      private villeService: VillesService,
      private utilsService: UtilsService,
      private localStorageService: LocalStorageService,
      private toastr: ToastrService,
      private routerExtService: RouterExtService,
  ) { }

  ngOnInit() {
    this.localeService.use('fr');

    this.chantier.isCasDeMontage  = this.router.url.includes('home-cdm');

    this.chantier.codeClient = this.localStorageService.getUser().codeClient;
    this.isLoadingGet = true;
    this.route.paramMap
        .pipe(
            switchMap((params: ParamMap) => params.get('idChantier') ? this.getChantier(params.get('idChantier')) : this.stopObservable()),
        )
        .subscribe();

    this.depts$ = this.deptService.getDepts();
    this.typeBatiments$ = this.typeBatimentService.getTypeBatiments();
    this.typeChantiers$ = this.typeChantierService.getTypeChantiers();
    this.rugosites$ = this.rugositeService.getRugosites();
  }

  public getVilles(dep: string) {
    this.isLoadingVille = true;
    return this.villeService.getVilles((this.chantier.ville as Ville).dep)
      .pipe(
        finalize(() => this.isLoadingVille = false)
      )
      .subscribe(villes => this.setVilles(villes));
  }

  public compareTypeBatiment(tb1: TypeBatiment, tb2: TypeBatiment) {
    return tb1 !== null && tb2 !== null && tb1.id === tb2.id;
  }

  public compareTypeChantier(tc1: TypeChantier, tc2: TypeChantier) {
    return tc1 !== null && tc2 !== null && tc1.id === tc2.id;
  }

  public compareRugosite(r1: Rugosite, r2: Rugosite) {
    return r1 && r2 && r1.categorie === r2.categorie;
  }

  public addOrUpdateChantier() {
    this.isLoading = true;
    const chantier = clone(this.chantier);

    // Control Ville
    const ville = this.getVille();
    this.isVilleConform = !!ville;
    chantier.ville = ville;

    // Control Dates
    this.isDateCorrect = this.controlAndTransformDates(chantier);

    if (this.isDateCorrect && this.isVilleConform) {
      this.chantierService.addOrUpdateChantier(chantier)
        .pipe(
          finalize( () => this.isLoading = false)
        )
        .subscribe((chantier) => {
          if (this.chantier.id) {
            this.toastr.success('Votre chantier a bien été modifié', 'Chantier');
          } else {
            this.toastr.success('Votre chantier a bien été créé', 'Chantier');
          }
          if (this.chantier.isCasDeMontage) {
            this.router.navigate(['/home-cdm/cas-de-montage/', chantier.id]);
          } else {
            this.router.navigate(['/home-menuiserie/repere/', chantier.id]);
          }
          this.createdChantier = chantier;
        });
    } else {
      this.isLoading = false;
    }
  }

  private getVille(): Ville | undefined {
    return this.villes.find((element) => element.ville === (this.chantier.ville as Ville).ville);
  }

  private getChantier(id: string) {
    return this.chantierService.getChantier(id)
        .pipe(
            tap(chantier => this.chantier = chantier),
            map (chantier => this.transformDates(chantier)),
            mergeMap( chantier => this.villeService.getVilles((chantier.ville as Ville).dep)),
            tap(villes => this.setVilles(villes)),
            finalize( () => this.isLoadingGet = false)
        );
  }

  private stopObservable() {
    this.isLoadingGet = false;
    return of();
  }

  private setVilles(villes: Ville[]) {
    this.villesStr = villes.map(ville => ville.ville);
    this.villes = villes;
  }

  private controlAndTransformDates(chantier: Chantier) {
    if (chantier.dateDebutStr && chantier.dateFinStr) {
      if (moment(chantier.dateDebutStr, 'DD/MM/YYYY') > moment(chantier.dateFinStr, 'DD/MM/YYYY')) {
        return false;
      }
    }

    if (chantier.dateDebutStr) {
      chantier.dateDebut = moment(chantier.dateDebutStr, 'DD/MM/YYYY').unix();
    }
    if (chantier.dateFinStr) {
      chantier.dateFin = moment(chantier.dateFinStr, 'DD/MM/YYYY').unix();
    }

    return true;
  }

  private transformDates(chantier: Chantier) {
    chantier.dateDebutStr = this.utilsService.tranformDateTimeStampToString(chantier.dateDebut);
    chantier.dateFinStr = this.utilsService.tranformDateTimeStampToString(chantier.dateFin);
    return chantier;
  }

  info(code: string, min: number = null, max: number = null) {
    this.isCollapsed = false;
    this.picto = clone(pictoMenuiserie.find(p => p.code === code));
  }

  close() {
    this.isCollapsed = true;
  }
}
