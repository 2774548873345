import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment';
import { HttpClient } from '@angular/common/http';
import { TypeChantier } from '../model/typeChantier.model';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class TypeChantierService {

    constructor(
        protected http: HttpClient,
        private config: ConfigService
    ) {}

    getTypeChantiers(): Observable<TypeChantier[]> {
        return this
            .http
            .get<TypeChantier[]>(this.config.apiEndpoint + environment.type_chantier_get_collection);
    }
}
