import { Component, OnInit, ViewChild } from '@angular/core';
import { ChantierService } from '../../shared/services/chantier.service';
import { Chantier } from '../../shared/model/chantier.model';
import { finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ElementConception } from '../../shared/model/elementConception.model';
import { VitragesService } from '../../shared/services/vitrages.service';
import { Observable, ReplaySubject } from 'rxjs';
import { Vitrage } from '../../shared/model/vitrage.model';
import { Gamme } from '../../shared/model/gamme.model';
import { GammesService } from '../../shared/services/gammes.service';
import * as clone from 'clone';
import { ElementConceptionService } from '../../shared/services/element-conception.service';
import { NgForm } from '@angular/forms';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MatiereService } from '../../shared/services/matiere.service';
import { Matiere } from '../../shared/model/matiere.model';

@Component({
  selector: 'app-repere',
  templateUrl: './repere.component.html',
  styleUrls: ['./repere.component.scss']
})
export class RepereComponent implements OnInit {

  @ViewChild('f') public form: NgForm;

  public chantier: Chantier;
  public repere: ElementConception = {} as ElementConception;
  public vitrages$: Observable<Vitrage[]>;
  public gammistes$: Observable<Gamme[]>;
  public matieres$: Observable<Matiere[]>;
  public selectedMatieres$: Observable<Matiere[]>;
  public selectedMatieresSubject$ = new ReplaySubject<Matiere[]>();
  public series: Gamme[] = [];
  public isolations: Gamme[] = [];
  public profils: Gamme[] = [];
  public tapees: Gamme[] = [];
  public bsModalRef: BsModalRef;

  public isLoading = false;
  public isLoadingDel = false;
  public isLoadingPoids = false;
  public isLoadingGet = true;
  public isLoadingTapee = false;
  public isLoadingProfil = false;
  public isLoadingIsolation = false;
  public isLoadingSerie = false;
  public isLoadingMatiere = true;

  private isInitGamme = true;
  private isInitTapee = true;
  private isInitProfil = true;
  private isInitIsolation = true;
  private isInitSerie = true;

  constructor(
    private chantierService: ChantierService,
    private route: ActivatedRoute,
    private router: Router,
    private vitrageService: VitragesService,
    private gammeService: GammesService,
    private repereService: ElementConceptionService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private matiereService: MatiereService,
  ) { }

  ngOnInit() {
    this.inteRepereDefaultDatas();
    this.initDatasFromUrl();
    this.selectedMatieres$ = this.selectedMatieresSubject$.asObservable();
    this.vitrages$ = this.vitrageService.getVitrages();

    this.gammistes$ = this.gammeService.getGammistes()
      .pipe(
        map(gammes => this.groupByMatiere(gammes))
      )
    ;

    this.matieres$ = this.matiereService.getMatieres()
      .pipe(
        tap(matieres => {
          if (!this.repere.matiere) {
            this.repere.matiere = matieres[0];
          }
        }),
        shareReplay(1),
        finalize(() => this.isLoadingMatiere = false),
      )
    ;

    this.matieres$.subscribe(matieres => this.selectedMatieresSubject$.next(matieres));
  }

  private groupByMatiere(gammes: Gamme[]): Gamme[] {
    const result = {};
    for (const gamme of gammes) {
      if (!result.hasOwnProperty(gamme.gammiste)) {
        result[gamme.gammiste] = {
          gammiste: gamme.gammiste,
          serie: gamme.serie,
          series: [],
          isolation: gamme.isolation,
          isolations: [],
          isolationRestante: gamme.isolationRestante,
          tapee: gamme.tapee,
          tapees: [],
          profil: gamme.profil,
          profils: [],
          matieres: [],
        };
      }
      result[gamme.gammiste].matieres.push(gamme.matiere);
      result[gamme.gammiste].series.push(gamme.serie);
      result[gamme.gammiste].isolations.push(gamme.isolation);
      result[gamme.gammiste].profils.push(gamme.profil);
      result[gamme.gammiste].tapees.push(gamme.tapee);
    }

    return Object.values(result);
  }

  private inteRepereDefaultDatas() {
    this.repere.volet = false;
    this.repere.coulissant = false;
    this.repere.corniere = false;
    this.repere.fenetre = true;
    this.repere.porte = false;
  }

  private initDatasFromUrl(): void {
    this.route.paramMap
      .pipe(
        switchMap<ParamMap, ElementConception | Chantier>((params: ParamMap) => this.manageGetRepereChantier(params)),
      )
      .subscribe(() => this.isLoadingGet = false);
  }

  private manageGetRepereChantier(params: ParamMap): Observable<ElementConception> | Observable<Chantier> {
    this.isLoadingGet = true;
    if (params.get('idRepere')) {
      return this.getRepere(params.get('idRepere'));
    }

    return this.getChantier(params.get('idChantier'));
  }

  public onGammistChange(gamme: Gamme) {
    if (this.isInitGamme && this.repere.id) {
      this.isInitGamme = false;
    } else {
      this.repere.serie = null;
      this.series = [];
      this.repere.isolation = null;
      this.repere.profil = null;
      this.profils = [];
      this.repere.tapee = null;
      this.tapees = [];
    }

    if (gamme) {
      this.reloadMatieres(gamme.matieres);
    }

    if (this.repere.gamme) {
      this.isLoadingSerie = true;
      this.gammeService.getSeries((this.repere.gamme as Gamme).gammiste)
        .pipe(
          map(gammes => this.groupByMatiere(gammes)),
          finalize(() => this.isLoadingSerie = false)
        )
        .subscribe( series => this.series = series);
    }
  }

  public onSerieChange() {
    if (this.isInitSerie && this.repere.id) {
      this.isInitSerie = false;
    } else {
      this.repere.isolation = null;
      this.repere.profil = null;
      this.profils = [];
      this.repere.tapee = null;
      this.tapees = [];
      this.isolations = [];
    }

    if (this.series[0]) {
      this.reloadMatieres(this.series[0].matieres);
    }

    if (this.repere.gamme
      && this.repere.serie) {
      this.isLoadingIsolation = true;
      this.gammeService.getIsolations((this.repere.gamme as Gamme).gammiste, this.repere.serie as string)
         .pipe(
           map(gammes => this.groupByMatiere(gammes)),
           finalize(() => this.isLoadingIsolation = false)
         )
         .subscribe( isolations => this.isolations = isolations);
    }
  }

  public onIsolationChange() {
    if (this.isInitIsolation && this.repere.id) {
      this.isInitIsolation = false;
    } else {
      this.repere.profil = null;
      this.profils = [];
      this.repere.tapee = null;
      this.tapees = [];
    }
    if (this.isolations[0]) {
      this.reloadMatieres(this.isolations[0].matieres);
    }

    if (this.repere.gamme
      && this.repere.serie
      && this.repere.isolation) {
      this.isLoadingProfil = true;
      this.gammeService.getProfils(
        (this.repere.gamme as Gamme).gammiste,
        this.repere.serie as string,
        null,
        this.repere.isolation as number,
      )
        .pipe(
          map(gammes => this.groupByMatiere(gammes)),
          finalize(() => this.isLoadingProfil = false)
        )
        .subscribe(profils => this.profils = profils);
    }
  }

  public onProfilChange() {
    if (this.isInitProfil && this.repere.id) {
      this.isInitProfil = false;
    } else {
      this.repere.tapee = null;
      this.tapees = [];
    }

    if (this.profils[0]) {
      this.reloadMatieres(this.profils[0].matieres);
    }

    if (this.repere.gamme
      && this.repere.serie
      && this.repere.isolation
      && this.repere.profil) {
      this.isLoadingTapee = true;
      this.gammeService.getTapees(
        (this.repere.gamme as Gamme).gammiste,
        this.repere.serie as string,
        this.repere.isolation.toString(),
        this.repere.profil as string)
        .pipe(
          map(gammes => this.groupByMatiere(gammes)),
          finalize(() => this.isLoadingTapee = false)
        )
        .subscribe(tapees => this.tapees = tapees)
      ;
    }
  }

  public addOrUpdateRepere(onContinue: boolean) {
    this.isLoading = true;
    const repere = clone(this.repere);
    repere.conception = this.chantier;
    repere.gamme = repere.gamme.gammiste;
    repere.tapee = repere.tapee ? repere.tapee.tapee : null;
    this
      .repereService
      .addOrUpdateRepere(repere)
      .pipe(
        finalize( () => this.isLoading = false)
      )
      .subscribe(() => {
        if (!this.repere.id) {
          this.repere.repere = null;
          this.toastr.success('Votre repère a bien été ajouté à votre chantier.', 'Menuiserie');
        } else {
          this.toastr.success('Votre repère a bien été modifié.', 'Menuiserie');
        }
        if (onContinue) {
          this.getChantier((this.chantier as Chantier).id.toString()).subscribe();
          window.scroll(0, 0);
        } else {
          this.router.navigate(['/home-menuiserie', (this.chantier as Chantier).id]);
        }
      });
  }

  public delete() {
    this.isLoadingDel = true;
    this.repereService.deleteRepere(this.repere.id.toString())
      .pipe(
        finalize( () => this.isLoadingGet = false)
      )
      .subscribe(() => {
        this.form.resetForm();
        this.toastr.success('Votre menuiserie a bien été supprimée', 'Menuiserie');
        this.router.navigate(['/home-menuiserie/repere/' + this.chantier.id]);
      });
  }

  submitForm(onContinue = true): void {
    this.form.ngSubmit.emit();
    if (!this.form.valid
      || !this.controlCote(this.repere.hauteur, 901, 10000)
      || !this.controlCote(this.repere.hauteur, 1, 99999)
    ) {
      return;
    }
    this.addOrUpdateRepere(onContinue);
  }

  controlCote(cote: number, min: number, max: number): boolean {
    return !cote || (cote >= min && cote <= max);
  }

  private getChantier(id: string): Observable<Chantier> {
    return this
      .chantierService
      .getChantier(id)
      .pipe(
        tap(chantier => this.chantier = chantier),
      )
    ;
  }

  private getRepere(id: string): Observable<ElementConception> {
    return this.repereService.getRepere(id)
      .pipe(
        map(repere => {
          repere.gamme = { gammiste: repere.gamme } as Gamme;
          repere.tapee = { tapee: repere.tapee } as Gamme;

          return repere;
        }),
        tap(repere => this.repere = repere),
        tap(repere => this.onGammistChange(null)),
        tap(repere => this.onSerieChange()),
        tap(repere => this.onIsolationChange()),
        tap(repere => this.onProfilChange()),
        tap(repere => this.getChantier((repere.conception as Chantier).id.toString()).subscribe(chantier => this.chantier = chantier)),
      );
  }

  public compareVitrage(v1: Vitrage, v2: Vitrage) {
    return v1 && v2 && v1.id === v2.id;
  }

  public compareSerie(serie1: string, serie2: string) {
    return serie1 && serie2 && serie1 === serie2;
  }

  public compareProfil(profil1: string, profil2: string) {
    return profil1 && profil2 && profil1 === profil2;
  }

  public compareTapee(g1: Gamme, g2: Gamme) {
    return g1 && g2 && g1.tapee === g2.tapee;
  }

  public compareIsolation(isolation1: number, isolation2: number) {
    return isolation1 && isolation2 && isolation1 === isolation2;
  }

  public compareGamme(g1: Gamme, g2: Gamme) {
    return g1 && g2 && g1.gammiste === g2.gammiste;
  }

  openDeleteConfirm() {
    const initialState = {
      message: 'Etes-vous sûr de vouloir supprimer ce repère ?',
      title: 'SUPPRESSION'
    };
    this.bsModalRef = this.modalService.show(ConfirmComponent, {initialState, animated: true, class: 'modal-lg'});
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result) {
        this.delete();
        this.bsModalRef.hide();
      }
    });
  }

  calculPoids() {
    if (this.repere.vitrage && this.repere.largeur && this.repere.hauteur) {
      this.isLoadingPoids = true;
      this.repereService.calculPoids(this.repere)
        .pipe(
          finalize( () => this.isLoadingPoids = false)
        )
        .subscribe((poids) => {
          this.repere.poidsTotal  = poids;
        });
    }
  }

  public reloadMatieres(typesMatiere: string[]) {
    this.isLoadingMatiere = true;
    this.matieres$
      .pipe(
        map((matieres: Matiere[]) => matieres.filter(matiere => typesMatiere.includes(matiere.type))),
        shareReplay(1),
        finalize(() => this.isLoadingMatiere = false),
      )
      .subscribe(matieres => this.selectedMatieresSubject$.next(matieres));
  }

}
