import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/security/auth-guard.service';

import { HomeComponent } from './home/home/home.component';
import { LoginComponent } from './core/login/login.component';
import { FixconceptComponent } from './fixconcept/fixconcept/fixconcept.component';
import { HomeCdmComponent } from './home-cdm/home-cdm/home-cdm.component';
import { CasDeMontageComponent } from './home-cdm/cas-de-montage/cas-de-montage.component';
import { HomeArchiveComponent } from './home-archive/home-archive/home-archive.component';
import { ReinitPwdComponent } from './core/login/reinit-pwd/reinit-pwd.component';
import { LoginLayoutComponent } from './core/login-layout/login-layout.component';
import { AppLayoutComponent } from './core/app-layout/app-layout.component';
import { RepereComponent } from './home-menuiserie/repere/repere.component';
import { ChantierComponent } from './home-menuiserie/chantier/chantier.component';
import { HomeMenuiserieComponent } from './home-menuiserie/home-menuiserie/home-menuiserie.component';

const PUBLIC_ROUTES: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'reinit-pwd', component: ReinitPwdComponent },
];

const PRIVATE_ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'home-menuiserie', component: HomeMenuiserieComponent },
  { path: 'home-cdm', component: HomeCdmComponent },
  { path: 'home-archive', component: HomeArchiveComponent },
  { path: 'home-menuiserie/conception', component: ChantierComponent },
  { path: 'home-menuiserie/conception/:idChantier', component: ChantierComponent },
  { path: 'home-cdm/conception', component: ChantierComponent },
  { path: 'home-cdm/conception/:idChantier', component: ChantierComponent },
  { path: 'home-menuiserie/repere/:idChantier', component: RepereComponent },
  { path: 'home-menuiserie/repere/:idChantier/:idRepere', component: RepereComponent },
  { path: 'home-cdm/cas-de-montage/:idChantier', component: CasDeMontageComponent },
  { path: 'home-cdm/cas-de-montage/:idChantier/:idCasDeMontage', component: CasDeMontageComponent },
  { path: 'home-cdm/:idChantier', component: HomeCdmComponent },
  { path: 'home-menuiserie/:idChantier', component: HomeMenuiserieComponent },
  { path: 'fixconcept', component: FixconceptComponent },

];

const APP_ROUTES: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '', component: LoginLayoutComponent, children: PUBLIC_ROUTES, data: { displayLogo: false } },
  { path: '', component: AppLayoutComponent, canActivate: [AuthGuard], children: PRIVATE_ROUTES, data: { displayLogo: true } },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
