export const environment = {

  // routes
  chantier_get_collection: '/api/mdt/conceptions',
  chantier_get_item: '/api/mdt/conceptions/{id}',
  chantier_post_collection: '/api/mdt/conceptions',
  chantier_patch_item: '/api/mdt/conceptions/{id}',
  dept_get_collection: '/api/mdt/depts',
  dept_get_item: '/api/mdt/depts/{id}',
  type_batiment_get_collection: '/api/mdt/types_batiments',
  type_batiment_get_item: '/api/mdt/types_batiments/{id}',
  type_chantier_get_collection: '/api/mdt/types_chantiers',
  type_chantier_get_item: '/api/mdt/types_chantiers/{id}',
  rugosite_get_collection: '/api/mdt/rugosites',
  rugosite_get_item: '/api/mdt/rugosites/{id}',
  user_get_collection: '/api/api_users',
  user_get_item: '/api/api_users/{id}',
  user_put_item: '/api/api_users/{id}',
  ville_get_collection: '/api/mdt/villes',
  ville_get_item: '/api/mdt/villes/{id}',
  matiere_get_collection: '/api/matieres',
  matiere_get_item: '/api/matieres/{id}',
  vitrage_get_collection: '/api/vitrages',
  vitrage_get_item: '/api/vitrages/{id}',
  repere_get_collection: '/api/mdt/reperes',
  repere_get_item: '/api/mdt/reperes/{id}',
  repere_post_collection: '/api/mdt/reperes',
  repere_patch_item: '/api/mdt/reperes/{id}',
  repere_delete_item: '/api/mdt/reperes/{id}',
  repere_calcul_weight: '/api/menuiseries/poids/calcul',
  piece_get_collection: '/api/mdt/pieces',
  piece_get_item: '/api/mdt/pieces/{id}',
  forme_get_item: '/api/mdt/formes/{id}',
  type_get_item: '/api/mdt/types/{id}',
  type_montage_get_item: '/api/mdt/type_de_montages/{id}',
  casdemontage_get_item: '/api/mdt/cas_de_montages/{id}',
  casdemontage_post_collection: '/api/mdt/cas_de_montages',
  casdemontage_patch_item: '/api/mdt/cas_de_montages/{id}',
  casdemontage_get_collection: '/api/mdt/cas_de_montages',
  casdemontage_delete_item: '/api/mdt/cas_de_montages/{id}',
  casDeMontage_export: '/api/mdt/export-cas-de-montage',

  gammes_get_gammiste_collection: '/api/mdt/gammes/gammiste',
  gammes_get_serie_collection: '/api/mdt/gammes/series',
  gammes_get_isolation_collection: '/api/mdt/gammes/isolations',
  gammes_get_type_isolation_collection: '/api/mdt/gammes/type_isolations',
  gammes_get_profil_collection: '/api/mdt/gammes/profils',
  gammes_get_tapee_collection: '/api/mdt/gammes/tapees',
  gammes_get_matiere_collection: '/api/mdt/gammes/matieres',
  gammes_get_by_cdm: '/api/mdt/gammes/gammeCdm',
  gammes_get_collection: '/api/mdt/gammes',

  conception_export_element: '/api/mdt/export-conception-element',
  suivi_chantier_export: '/api/mdt/export-suivi-chantier',

  login_forgot_pwd: '/users/forgot-pwd',
  login_reinit_pwd: '/users/reinit-pwd',
  api_version: '/version',

  sendMailWithClientInformations: '/api/send-mail-for-help',
};
