import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Rugosite } from '../model/rugosite.model';
import { Matiere } from '../model/matiere.model';

@Injectable({
    providedIn: 'root',
})
export class MatiereService {

    constructor(
        protected http: HttpClient,
        private config: ConfigService
    ) {}

    getMatieres(): Observable<Matiere[]> {
        return this
            .http
            .get<Matiere[]>(this.config.apiEndpoint + environment.matiere_get_collection);
    }
}
