import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment';
import { HttpClient } from '@angular/common/http';
import { Dept } from '../model/dept.model';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class DeptsService {

    constructor(
        protected http: HttpClient,
        private config: ConfigService
    ) {}

    getDepts(): Observable<Dept[]> {
        return this
            .http
            .get<Dept[]>(this.config.apiEndpoint + environment.dept_get_collection);
    }
}
