import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { ChantierService } from '../../shared/services/chantier.service';
import { Chantier } from '../../shared/model/chantier.model';
import { finalize } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { PdfService } from '../../shared/services/pdf.service';
import { ToastrService } from 'ngx-toastr';
import { ElementTableauChantier } from '../../shared/model/elementTableauChantier.model';
import { LoadingComponent } from '../../shared/modals/loading/loading.component';
import { BsLocaleService, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { Dept } from '../../shared/model/dept.model';
import { DeptsService } from '../../shared/services/depts.service';
import { Ville } from '../../shared/model/ville.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-home-archive-content',
  templateUrl: './home-archive-content.component.html',
  styleUrls: ['./home-archive-content.component.scss']
})
export class HomeArchiveContentComponent implements OnInit {

  @ViewChild('f') public form: NgForm;
  @Input() isMenuiserie = true;

  collapsedElementID: number;
  chantiers: Chantier[] = [];
  isLoading = false;
  bsModalRef: BsModalRef;
  chantier: { ville: Ville } & Chantier = {
    elementsTableauChantiers: [],
    ville: {dep: null, ville: null, id: null},
    libelle: null,
    id: null,
    isCasDeMontage: null,
    codeClient: null,
    typeBat: null,
    dateFinStr: null,
    dateDebutStr: null,
    dateDebut: null,
    dateFin: null,
    type: null,
    coutHoraireGa: null,
    elementsConceptions: [],
    selectedCdm: null,
    selectedElement: null,
    casDeMontages: null,
    rugosite: null,
    hauteurBatiment: null,
  };
  depts$: Observable<Dept[]>;

  constructor(
      private chantierService: ChantierService,
      private localStorageService: LocalStorageService,
      private pdfService: PdfService,
      private toastr: ToastrService,
      private modalService: BsModalService,
      private localeService: BsLocaleService,
      private deptService: DeptsService,
  ) {}

  ngOnInit(): void {
    this.localeService.use('fr');
    this.depts$ = this.deptService.getDepts();
    this.getChantiers();
  }


  public getChantiers() {
    this.isLoading = true;
    const codeClient = this.localStorageService.getUser().codeClient;
    this.chantierService.getChantiers(codeClient,
      true,
      false,
      !this.isMenuiserie,
      this.chantier.dateDebutStr, this.chantier.libelle, this.chantier.ville.ville, this.chantier.ville.dep)
        .pipe(
            finalize(() => this.isLoading = false)
        )
        .subscribe(chantiers => this.chantiers = chantiers);
  }


  private exportElement(event, chantier: Chantier): void {
    event.stopPropagation();
    const initialState = {
      title: 'Export'
    };
    this.bsModalRef = this.modalService.show(LoadingComponent, {initialState, animated: true, class: 'modal-lg', backdrop: false});
    if (!chantier.selectedElement) {
      this.toastr.error('Pour exporter, veuillez choisir un type de patte de fixation.', 'Export');
    } else {
      const idConception = chantier.selectedElement.idConception;
      const famille = chantier.selectedElement.famille;
      this.chantierService.getConceptionExport(idConception, famille)
        .pipe(
          finalize(() => this.bsModalRef.hide())
        )
        .subscribe(pdf => {
          this.pdfService.openFromBase64(pdf);
        });
    }
  }

  selectLine(elementTableau: ElementTableauChantier, chantier: Chantier) {
    chantier.selectedElement = elementTableau;
  }

  reinit() {
    this.form.resetForm();
  }
}
