import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private toastService: ToastrService
  ) {
  }

  canActivate(): boolean {
    if (this.authenticationService.isLoggedIn()) {
      return true;
    }
    this.authenticationService.disconnect();
    this.toastService.warning('Votre session a expirée.', 'Login');
    return false;
  }
}
