import { Component, OnInit } from '@angular/core';
import { ChantierService } from '../../shared/services/chantier.service';
import { Chantier } from '../../shared/model/chantier.model';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { PdfService } from '../../shared/services/pdf.service';
import { ToastrService } from 'ngx-toastr';
import { ElementTableauChantier } from '../../shared/model/elementTableauChantier.model';
import { LoadingComponent } from '../../shared/modals/loading/loading.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';
import { ElementConception } from '../../shared/model/elementConception.model';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  templateUrl: './home-menuiserie.component.html',
  styleUrls: ['./home-menuiserie.component.scss']
})
export class HomeMenuiserieComponent implements OnInit {
  collapsedElementID: number;
  chantiers: Chantier[] = [];
  isLoading = false;
  direction = 'asc';
  isSortedByVille = false;
  isSortedByDate = false;
  bsModalRef: BsModalRef;
  private tableErrors: ElementConception[] = [];

  constructor(
      private chantierService: ChantierService,
      private localStorageService: LocalStorageService,
      private pdfService: PdfService,
      private toastr: ToastrService,
      private modalService: BsModalService,
      private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getChantiers();
  }

  public sortVille() {
    this.isSortedByVille = true;
    this.isSortedByDate = false;
    this.chantiers = this.chantiers.sort((a: Chantier, b: Chantier) => {
      const aVille = (typeof a.ville === 'string') ? a.ville : a.ville.ville;
      const bVille = (typeof b.ville === 'string') ? b.ville : b.ville.ville;

      if (this.direction === 'asc') {
        return aVille.localeCompare(bVille);
      } else {
        return bVille.localeCompare(aVille);
      }
    });
  }

  public sortDate() {
    this.isSortedByVille = false;
    this.isSortedByDate = true;
    this.chantiers = this.chantiers.sort((a: Chantier, b: Chantier) => {
      if (this.direction === 'asc') {
        return this.compareDate(a.dateDebut, b.dateDebut);
      } else {
        return this.compareDate(b.dateDebut, a.dateDebut);
      }
    });
  }

  public sort() {
    if (this.isSortedByDate) {
      this.sortDate();
    } else if (this.isSortedByVille) {
      this.sortVille();
    }
  }

  private getChantiers(withShowDetail = true) {
    this.isLoading = true;
    const codeClient = this.localStorageService.getUser().codeClient;
    this
      .chantierService
      .getChantiers(codeClient, false, false, false)
      .pipe(
        tap(chantiers => this.checkErrorsRepere(chantiers)),
        finalize(() => {
          this.isLoading = false;
          this.informErrors();
        })
      )
      .subscribe(chantiers => {
        this.chantiers = chantiers;
        if (withShowDetail) {
          setTimeout(() => this.showChantierDetail(), 2000);
        }
      })
    ;
  }

  private showChantierDetail(): void {
    this
      .route
      .paramMap
      .pipe(
        filter((params: ParamMap) => !!params.get('idChantier')),
        map((params: ParamMap) => params.get('idChantier')),
      )
      .subscribe(idChantier => {
        this.collapsedElementID = +idChantier;
        document.getElementById(idChantier).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      })
    ;
  }

  private checkErrorsRepere(chantiers: Chantier[]) {
    chantiers.forEach( c => {
      c.elementsTableauChantiers.forEach(etc => {
        if (etc.isError && this.tableErrors.find(repere => repere.id === etc.repere.id)) {
          etc.repere.conception = c;
          this.tableErrors.push(etc.repere);
        }
      });
    });
  }

  private informErrors() {
    if (this.tableErrors.length > 0) {
      let message = 'Dimensions incorrectes pour les repères suivants : <ul>';
      this.tableErrors.forEach(repere => {
        message +=
          '<li>' +
            '<a [routerLink]="[\'home-menuiserie/repere\', ' + (repere.conception as Chantier).id + ', ' + repere.id + ']">' +
                repere.repere +
            '</a>' +
          '</li>';
      });
      message += '</ul>';
      this.toastr.error(message, 'Erreurs de saisie');
    }
  }

  private compareDate(dateA: number, dateB: number) {
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  public exportElement(elementTableau: ElementTableauChantier, event, chantier: Chantier): void {
    event.stopPropagation();
    chantier.selectedElement = elementTableau;
    if (!chantier.selectedElement) {
      this.toastr.error('Pour exporter, veuillez choisir un type de patte de fixation.', 'Export');
    } else {
      const initialState = {
        title: 'Export'
      };
      this.bsModalRef = this.modalService.show(LoadingComponent, {initialState, animated: true, class: 'modal-lg'});
      const idConception = chantier.selectedElement.idConception;
      const famille = chantier.selectedElement.famille;
      this.chantierService.getConceptionExport(idConception, famille)
        .pipe(
          finalize(() => this.bsModalRef.hide())
        )
        .subscribe(pdf => {
          this.pdfService.openFromBase64(pdf);
        });
    }
  }

  selectLine(elementTableau: ElementTableauChantier, chantier: Chantier) {
    chantier.selectedElement = elementTableau;
  }

  archiveChantier(idChantier: number) {
    const initialState = {
      message: 'Etes-vous sûr de vouloir archiver cette conception ?' +
        '<br>' +
        'Si vous confirmez, les informations liées à cette conception seront déplacées dans la partie <strong>ARCHIVES</strong>',
      title: 'ARCHIVAGE'
    };
    this.bsModalRef = this.modalService.show(ConfirmComponent, {initialState, animated: true, class: 'modal-lg'});
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result) {
        this.isLoading = true;
        this.chantierService.archiveChantier(idChantier)
          .subscribe(() => this.getChantiers(false));
        this.bsModalRef.hide();
      }
    });
  }
}
