import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { FacebookModule } from 'ngx-facebook';
import { BsDropdownModule, CollapseModule } from 'ngx-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReinitPwdComponent } from './login/reinit-pwd/reinit-pwd.component';
import { MdpOublieModalComponent } from './login/motDePasseOublie/mdp-oublie-modal.component';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    AppLayoutComponent,
    ReinitPwdComponent,
    MdpOublieModalComponent,
    LoginLayoutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    FacebookModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    SharedModule
  ],
  providers: [],
  entryComponents: [MdpOublieModalComponent]
})
export class CoreModule { }
