import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment';
import { HttpClient } from '@angular/common/http';
import { CasDeMontage } from '../model/cas-de-montage.model';
import { Piece } from '../model/typos/piece.model';
import { Type } from '../model/typos/type.model';
import { Typo } from '../model/typos/typo.model';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class CasDeMontageService {

    constructor(
        protected http: HttpClient,
        private config: ConfigService
    ) {}

    addOrUpdateCasDeMontage(casDeMontage: CasDeMontage): Observable<CasDeMontage> {

      casDeMontage.conception = environment.chantier_get_item.replace('{id}', (casDeMontage.conception as string));
      casDeMontage.piece = environment.piece_get_item.replace('{id}', (casDeMontage.piece as Piece).id.toString());
      casDeMontage.type = environment.type_get_item.replace('{id}', (casDeMontage.type as Type).id.toString());
      casDeMontage.forme = environment.forme_get_item.replace('{id}', (casDeMontage.forme as Typo).id.toString());
      casDeMontage.typeMontage = environment.type_montage_get_item.replace('{id}', (casDeMontage.typeMontage as Typo).id.toString());

      if (casDeMontage.id) {
        return this
          .http
          .patch<CasDeMontage>(
            this.config.apiEndpoint + environment.casdemontage_patch_item.replace('{id}', casDeMontage.id.toString()), casDeMontage);
      }
      return this
        .http
        .post<CasDeMontage>(this.config.apiEndpoint + environment.casdemontage_post_collection, casDeMontage);
    }

    getCasDeMontage(id: string): Observable<CasDeMontage> {
      return this
        .http
        .get<CasDeMontage>(this.config.apiEndpoint + environment.casdemontage_get_item.replace('{id}', id));
    }

    deleteCasDeMontage(id: string): Observable<void> {
      return this
        .http
        .delete<void>(this.config.apiEndpoint + environment.casdemontage_delete_item.replace('{id}', id));
    }
}
