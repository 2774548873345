import { NgModule } from '@angular/core';

import { BsDatepickerModule, CollapseModule, TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { ChantierComponent } from './chantier/chantier.component';
import { RepereComponent } from './repere/repere.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared/shared.module';
import { HomeMenuiserieComponent } from './home-menuiserie/home-menuiserie.component';
import { SidepanelComponent } from '../shared/component/sidepanel/sidepanel.component';

defineLocale('fr', frLocale);

@NgModule({
  declarations: [
    HomeMenuiserieComponent,
    ChantierComponent,
    RepereComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    RouterModule,
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    SharedModule
  ],
  providers: [],
})
export class HomeMenuiserieModule { }
