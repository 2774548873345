import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UserInactivityComponent } from '../../shared/modals/userInactivity/user-inactivity.component';
import { ConfigService } from '../../shared/services/config.service';

@Component({
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  public userActivity;
  public userInactive: Subject<any> = new Subject();
  public displayLogo = true;
  public bsModalRef: BsModalRef;
  public isModalShown = false;
  public subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private configService: ConfigService,
    private changeDetection: ChangeDetectorRef,
  ) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      clearTimeout(this.userActivity);
      this.openModalUserInactivity();
    });
  }

  /**
   * @inheritDoc
   */
  ngOnInit() {
    this.displayLogo = Boolean(this.activatedRoute.snapshot.data.displayLogo);
  }

  private openModalUserInactivity() {
    const combine = combineLatest(
      this.modalService.onShow,
      this.modalService.onShown,
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());

    this.subscriptions.push(
      this.modalService.onShow.subscribe((reason: string) => {
        this.isModalShown = true;
      })
    );
    this.subscriptions.push(
      this.modalService.onShown.subscribe((reason: string) => {
        this.isModalShown = true;
      })
    );
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        this.isModalShown = false;
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.isModalShown = false;
        this.unsubscribe();
      })
    );

    this.subscriptions.push(combine);

    this.bsModalRef = this.modalService.show(UserInactivityComponent, {animated: true, class: 'modal-lg'});
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.configService.timeToDetectInactivity);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    if (!this.isModalShown) {
      this.setTimeout();
    }
  }

}
