import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';

import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';

import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { SharedModule } from './shared/shared.module';
import { FixconceptModule } from './fixconcept/fixconcept.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { MomentModule } from 'ngx-moment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './shared/interceptor/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { InfoComponent } from './shared/modals/info/info.component';
import { HomeCdmModule } from './home-cdm/home-cdm.module';
import { HomeArchiveModule } from './home-archive/home-archive.module';
import { MyErrorHandler } from './shared/interceptor/my-error-handler.interceptor';
import { ConfigService } from './shared/services/config.service';
import { HomeMenuiserieModule } from './home-menuiserie/home-menuiserie.module';

defineLocale('fr', frLocale);
registerLocaleData(localeFr);

export function jwtOptionsFactory() {
  return {
    tokenGetter,
    whitelistedDomains: [
      'api-louineau-recette.fr',
      'api-louineau-recette.fr:8080',
      'api.louineau.com',
      'api.louineau.com:8443',
      'localhost:8000',
      'localhost',
      'mesdevis.louineau.com'
    ],
    skipWhenExpired: true
  };
}
export function tokenGetter(): string {
  return localStorage.getItem('access_token');
}

export function configLoader(config: ConfigService) {
  return () => config.load();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HomeModule,
    HomeCdmModule,
    HomeMenuiserieModule,
    HomeArchiveModule,
    FixconceptModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: []
      }
    }),
    SharedModule,
    BsDatepickerModule.forRoot(),
    MomentModule,
    ToastrModule.forRoot({
      toastComponent: InfoComponent,
      positionClass: 'toast-top-center',
      closeButton: true,
    }),
  ],
  providers: [
    ConfigService,
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: configLoader, deps: [ConfigService], multi: true, },
    {
      provide: ErrorHandler,
      useClass: MyErrorHandler,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
