import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PdfService {

  download(filename: string, response: any) {
    const dataType = response.type;
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([response], {type: dataType}));
    if (filename) {
      downloadLink.setAttribute('download', filename);
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  openFromBase64(base64: string): void {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    const newWindow = window.open('_blank');
    newWindow.onload = () => {
      newWindow.location.href = fileURL;
    };
  }
}
