import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/security/authentication.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { User } from '../../shared/model/user.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public displayLogo = true;

  isCollapsed = true;
  user: User;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService,
  ) {}

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.displayLogo = Boolean(this.activatedRoute.snapshot.data.displayLogo);
    this.localStorageService.getAsyncUser()
      .subscribe( user => this.user = user);
  }

  signout() {
    this.authenticationService.disconnect();
  }
}
