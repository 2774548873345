import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Gamme } from '../model/gamme.model';
import { CasDeMontage } from '../model/cas-de-montage.model';
import { Piece } from '../model/typos/piece.model';
import { Typo } from '../model/typos/typo.model';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class GammesService {

    constructor(
        protected http: HttpClient,
        private config: ConfigService
    ) {}

    getGammistes(pieceMurRideau: string = null): Observable<Gamme[]> {
      const params: {pieceMurRideau?: string} = {};
      if (pieceMurRideau) {
        params.pieceMurRideau = pieceMurRideau;
      }
      return this
            .http
            .get<Gamme[]>(this.config.apiEndpoint + environment.gammes_get_gammiste_collection, {params});
    }

    getSeries(gammiste: string, pieceMurRideau: string = null): Observable<Gamme[]> {
      const params: {gammiste?: string, pieceMurRideau?: string} = {};
      params.gammiste = gammiste;
      if (pieceMurRideau) {
        params.pieceMurRideau = pieceMurRideau;
      }
      return this
        .http
        .get<Gamme[]>(this.config.apiEndpoint + environment.gammes_get_serie_collection, {params});
    }

    getIsolations(gammiste: string, serie: string, typeIsolation: string = null, pieceMurRideau: string = null): Observable<Gamme[]> {
      const params: {gammiste?: string, serie?: string, typeIsolation?: string, pieceMurRideau?: string} = {};
      params.gammiste = gammiste;
      params.serie = serie;
      if (typeIsolation) {
        params.typeIsolation = typeIsolation;
      }
      if (pieceMurRideau) {
        params.pieceMurRideau = pieceMurRideau;
      }
      return this
        .http
        .get<Gamme[]>(this.config.apiEndpoint + environment.gammes_get_isolation_collection, {params});
    }

    getProfils(gammiste: string, serie: string, typeIsolation: string, isolation: number,
               pieceMurRideau: string = null): Observable<Gamme[]> {
      const params: {gammiste?: string, serie?: string, typeIsolation?: string, isolation?: string, pieceMurRideau?: string} = {};
      params.gammiste = gammiste;
      params.serie = serie;
      if (isolation) {
        params.isolation = isolation.toString();
      }
      if (typeIsolation) {
        params.typeIsolation = typeIsolation;
      }
      if (pieceMurRideau) {
        params.pieceMurRideau = pieceMurRideau;
      }
      return this
        .http
        .get<Gamme[]>(this.config.apiEndpoint + environment.gammes_get_profil_collection, {params});
    }

    getTapees(gammiste: string, serie: string, isolation: string, profil: string): Observable<Gamme[]> {
      const params = {gammiste, serie, isolation, profil};
      return this
        .http
        .get<Gamme[]>(this.config.apiEndpoint + environment.gammes_get_tapee_collection, {params});
    }


    getGammeByCdm(cdm: CasDeMontage) {
      const params = {
        gamme: cdm.gamme,
        profil: cdm.profil,
        serie: cdm.serie,
        semelleDebordante: cdm.semelleDebordante.toString(),
        piece: (cdm.piece as Piece).id.toString(),
        forme: (cdm.forme as Typo).id.toString(),
        type: (cdm.type as Typo).id.toString()
      };

      return this
        .http
        .get<Gamme[]>(this.config.apiEndpoint + environment.gammes_get_by_cdm, {params})
        .pipe(
          map(gammes => gammes[0])
        )
        ;
    }

    getGammeByCode(codeproduit: string) {
      const params = {
        codeproduit,
        das: '2'
      };

      return this
        .http
        .get<Gamme[]>(this.config.apiEndpoint + environment.gammes_get_collection, {params})
        .pipe(
          map(gammes => gammes[0])
        )
        ;
    }
}
