import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {User} from '../model/user.model';

@Injectable({
  providedIn: 'root'

})
export class LocalStorageService {
  private static readonly USER_KEY = 'user';
  private static readonly TOKEN_KEY = 'token';
  protected readonly user$ = new ReplaySubject<User>(1);
  protected user: User;
  protected token: string;

  constructor() {
    this.initUser();
  }

  setUser(user: User): void {
    if (localStorage) {
      localStorage.setItem(LocalStorageService.USER_KEY, JSON.stringify(user));
    }

    this.user = user;
    this.user$.next(user);
  }

  getUser(): User {
    return this.user;
  }

  setToken(token: string): void {
    if (localStorage) {
      if (token) {
        localStorage.setItem(LocalStorageService.TOKEN_KEY, token);
      } else {
        localStorage.removeItem(LocalStorageService.TOKEN_KEY);
      }
    }

    this.token = token;
  }

  getToken(): string {
    const storedToken = localStorage.getItem(LocalStorageService.TOKEN_KEY);

    if (!this.token) {
      this.setToken(storedToken);
    }

    // Si l'utilisateur a été loggé dans un autre onglet
    if (this.token !== storedToken) {
      this.initUser();
      this.token = storedToken;
    }

    return storedToken;
  }

  public getAsyncUser(): Observable<User> {
    return this.user$;
  }

  public clear() {
    this.user$.next(null);
    localStorage.clear();
  }

  protected initUser(): void {
    this.user = JSON.parse(localStorage.getItem(LocalStorageService.USER_KEY));

    if (this.user) {
      this.user$.next(this.user);
    }
  }
}
