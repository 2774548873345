import {Component, OnInit, ViewChild} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { AsYouType } from 'libphonenumber-js';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-changer-mdp',
  templateUrl: './user-inactivity.component.html',
  styleUrls: ['./user-inactivity.component.scss']
})
export class UserInactivityComponent implements OnInit {

  phoneNumber: string;
  lastname: string;
  firstname: string;
  onClose: Subject<boolean>;
  isLoading = false;
  @ViewChild('f') f: NgForm;

  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService
    ) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  sendMailWithClientPhoneNumber() {
    if (this.validatePhone(this.phoneNumber) && this.firstname && this.lastname && this.f.form.valid) {
      this.isLoading = true;
      this.userService.sendMailWithClientInformations(this.phoneNumber, this.firstname, this.lastname)
        .pipe(
          finalize( () => this.isLoading = false)
        )
        .subscribe(() => this.bsModalRef.hide());
    }
  }

  validatePhone(phone) {
    let regexpNumber = new RegExp('[0-9 ]{14}');
    return regexpNumber.test(phone);
  }

  formatPhoneNumber(telephone: string) {
    this.phoneNumber= new AsYouType('FR').input(telephone);
  }
}
