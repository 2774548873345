import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../shared/security/authentication.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-mdp-oublie-modal',
  templateUrl: './mdp-oublie-modal.component.html',
  styleUrls: ['./mdp-oublie-modal.component.scss']
})
export class MdpOublieModalComponent implements OnInit {

  email: string;
  onClose: Subject<boolean>;
  isLoading = false;

  constructor(
    public bsModalRef: BsModalRef,
    protected loginService: AuthenticationService) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  confirm() {
    this.isLoading = true;
    this.loginService.send(this.email)
      .pipe(
        finalize( () => this.isLoading = false)
      )
      .subscribe( () => this.bsModalRef.hide()
      );
  }
}
