import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment';
import { HttpClient } from '@angular/common/http';
import { Ville } from '../model/ville.model';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class VillesService {

    constructor(
        protected http: HttpClient,
        private config: ConfigService
    ) {}

    getVilles(dep: string): Observable<Ville[]> {
        const params = {dep};
        return this
            .http
            .get<Ville[]>(this.config.apiEndpoint + environment.ville_get_collection, {params});
    }
}
