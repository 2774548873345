import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/internal/operators';
import { LocalStorageService } from '../services/local-storage.service';
import { UserService } from '../services/user.service';
import { User } from '../model/user.model';
import { ConfigService } from '../services/config.service';

const loginUrl = '/users/login';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private httpService: HttpClient,
    private jwtService: JwtHelperService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private config: ConfigService
  ) {
  }

  /**
   * Authentifie et stocke l'utilisateur.
   */
  public connect(email: string, password: string): Observable<any> {
    return this.getToken(email, password)
      .pipe(
        map<{ username: string }, string>(obj => obj.username),
        mergeMap<string, User>(username => this.userService.getUserByUsername(username)),
        tap<User>(user => this.localStorageService.setUser(user)),
      );
  }

  /**
   * Déconnecte l'utilisateur et redirige vers la page de login.
   */
  public disconnect() {
    this.localStorageService.clear();
    this.router.navigate(['login']);
  }

  /**
   * Retourne s'il existe un jeton de connexion valide.
   */
  isLoggedIn(): boolean {
    const token = this.localStorageService.getToken();

    return token && !this.jwtService.isTokenExpired(token);
  }

  public send(user: string) {
    const apiCreateEndpoint = this.config.apiEndpoint + environment.login_forgot_pwd;
    return this.httpService.post(apiCreateEndpoint, {email: user})
      .pipe(
        catchError(this.handleError)
      );
  }

  public changePwd(pwd: string, token: string): Observable<any> {
    const apiCreateEndpoint = this.config.apiEndpoint + environment.login_reinit_pwd;

    return this.httpService.post(apiCreateEndpoint, {pwd, token})
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    if (err.status === 403) {
      return throwError(err.error.message);
    }
    return throwError('Une erreur inconnue est survenue');
  }

  /**
   * Retourne l'utilisateur correspondant aux identifiants en arguments.
   */
  private getToken(email: string, password: string): Observable<{ username: string }> {
    const apiCreateEndpoint: string = this.config.apiEndpoint + loginUrl;
    const parameters = {username: email, password};

    return this.httpService.post<{ token: string }>(apiCreateEndpoint, parameters)
      .pipe(
        map<{ token: string }, string>(result => result.token),
        tap<string>(token => this.localStorageService.setToken(token)),
        map<string, string>(token => token.split('.')[1]),
        map<string, string>(payload => atob(payload)),
        map<string, { username: string }>(payload => JSON.parse(payload)),
      );
  }
}
