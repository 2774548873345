import { NgModule } from '@angular/core';

import { BsDatepickerModule, CollapseModule, TabsModule, TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared/shared.module';
import { HomeArchiveComponent } from './home-archive/home-archive.component';
import { HomeArchiveContentComponent } from './home-archive-content/home-archive-content.component';

defineLocale('fr', frLocale);

@NgModule({
  declarations: [
    HomeArchiveComponent,
    HomeArchiveContentComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    RouterModule,
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    SharedModule,
    TabsModule.forRoot()
  ],
  providers: [],
})
export class HomeArchiveModule { }
