import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {

    constructor(
    ) {}

    public tranformDateTimeStampToString(date: number) {
        return moment.unix(date).format('DD/MM/YYYY');
    }

    public tranformDateStringToTimestamp(date: string) {
        return moment(date, 'DD/MM/YYYY').valueOf();
    }
}
