import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Picto } from '../../model/picto.model';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss']
})
export class SidepanelComponent implements OnInit, OnChanges {

  @Output() isCollapsed = new EventEmitter<void>();
  @Input() picto: Picto = null;
  @Input() min: number = null;
  @Input() max: number = null;
  @Input() srcImage = null;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.picto){
      this.picto.infos.forEach(i => {
        if (this.min) {
          i.texte = i.texte.replace('(min)*', this.min.toString());
        }
        if (this.max) {
          i.texte = i.texte.replace('(max)*', this.max.toString());
        }
      });
    }
  }


  close() {
    this.isCollapsed.emit();
  }

}
